import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { getDistance } from 'geolib';

const GEOLOCATION_MARKER_PRECISION = 4;
const GEOLOCATION_CONFLICT_ADJUSTMENT = 0.0001;

export default Controller.extend({
  fastboot: service(),
  favouriteVenuesManager: service('managers/favourite-venues-manager'),
  geolocationFetcher: service('fetchers/geolocation-fetcher'),
  media: service(),
  router: service(),
  eventsFetcher: service('fetchers/events-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),
  sessionStorage: service('session-storage'),
  eventsIndexController: controller('web.events.index'),

  mapWithinZoomLimits: true,
  fetchForWebEventsList: false,
  fetchEventsWithPhotosOnly: false,

  selectedVenueId: readOnly('eventsIndexController.selectedVenueId'),
  selectedPage: readOnly('eventsIndexController.page'),

  selectedVenue: computed('selectedVenueId', 'venuesFetcher.fetchedVenues.[]', 'mapWithinZoomLimits',
    function() {
      if (this.selectedVenueId)
        return this.venuesFetcher.fetchedVenues.find((venue) => venue.id === this.selectedVenueId);

      // don't return events if we're currently on map view and the map is zoomed out too much
      const mapTooZoomedOut = this.router.currentRouteName === 'web.events.index.map'
        && !this.mapWithinZoomLimits;

      if (window.globalMap && !mapTooZoomedOut) {
        let mapBounds = window.globalMap.getBounds();
        this.setVenuesWithinMap(mapBounds);
      }

      return null;
    }
  ),

  nearbyVenues: computed('venuesFetcher.fetchedVenues.[]', function() {
    const venues = this.venuesFetcher.fetchedVenues;
    const mappedVenues = [];

    venues.forEach(venue => {
      let venuePlaced = false;

      while (!venuePlaced) {
        const position = `${venue.latitude.toFixed(GEOLOCATION_MARKER_PRECISION)}:${venue.longitude.toFixed(GEOLOCATION_MARKER_PRECISION)}`;

        if (mappedVenues[position]) {
          // another venue has the same position, alter it slightly
          venue.latitude += GEOLOCATION_CONFLICT_ADJUSTMENT;
          venue.longitude += GEOLOCATION_CONFLICT_ADJUSTMENT;
        } else {
          mappedVenues[position] = venue;
          venuePlaced = true
        }
      }
    });

    if (!this.selectedVenueId && venues.length >= 0)
      this._callEventsFetcher(venues.map(v => v.id));

    return venues;
  }),

  totalEventCount: computed.readOnly('eventsFetcher.totalEventCount'),
  totalPageCount: computed.readOnly('eventsFetcher.totalPageCount'),

  events: computed('eventsFetcher.fetchedEvents.[]', 'selectedVenueId', 'selectedPage',
    function() {
      if (this.selectedVenueId) {
        return this.eventsFetcher.fetchedEvents.filter(event => event.venue.id == this.selectedVenueId);
      } else {
        return this.eventsFetcher.fetchedEvents;
      }
    }
  ),

  mappedEvents: computed('events.[]', 'model.userLocation', function() {
    return this.events.map(event => {
      const distanceFromUser = this.model.userLocation
        ? getDistance(
          { latitude: this.model.userLocation.latitude, longitude: this.model.userLocation.longitude },
          { latitude: event.venue.latitude, longitude: event.venue.longitude }) / 1000.0
        : null;

      return {
        ...event,
        distanceFromUser
      };
    });
  }),

  sortedEvents: computed('mapWithinZoomLimits', 'model.userLocation', 'mappedEvents.[]', function() {
    // don't return events if we're currently on map view and the map is zoomed out too much
    if (this.router.currentRouteName === 'web.events.index.map' && !this.mapWithinZoomLimits)
      return [];

    return this.mappedEvents.sort((eventA, eventB) => {
      const eventADate = moment(eventA.startedAt).tz(eventA.venue.timezone).startOf('day');
      const eventBDate = moment(eventB.startedAt).tz(eventB.venue.timezone).startOf('day');
      return eventADate.diff(eventBDate) + (eventA.distanceFromUser - eventB.distanceFromUser);
    });
  }),

  reversedSortedEvents: computed('mapWithinZoomLimits', 'model.userLocation', 'mappedEvents.[]', function() {
    // don't return events if we're currently on map view and the map is zoomed out too much
    if (this.router.currentRouteName === 'web.events.index.map' && !this.mapWithinZoomLimits)
      return [];

    return this.mappedEvents.sort((eventA, eventB) => {
      const eventADate = moment(eventA.startedAt).tz(eventA.venue.timezone).startOf('day');
      const eventBDate = moment(eventB.startedAt).tz(eventB.venue.timezone).startOf('day');
      return eventBDate.diff(eventADate);
    });
  }),

  eventCountByVenue: computed('sortedEvents.[]', function() {
    return this.sortedEvents.reduce((memo, event) => {
      if (!memo.hasOwnProperty(event.venue.id)) memo[event.venue.id] = 0;

      memo[event.venue.id]++;

      return memo;
    }, {});
  }),

  isFarFromGeolocation: computed('sessionStorage.mapCenter', 'userLocation', function() {
    return this.geolocationFetcher.calcDistance(this.sessionStorage.read('mapCenter'), this.model.userLocation) > 75;
  }),

  setDateRange({ fromDate, toDate }) {
    this.eventsIndexController.setProperties({ fromDate, toDate });
    this._callEventsFetcher(this.selectedVenueId ? [ this.selectedVenueId ] : this.nearbyVenues.map(v => v.id));
  },

  setVenue(venue) {
    this.eventsIndexController.set('selectedVenueId', venue);
  },

  setVenuesWithinMap(mapBounds) {
    // no need to fetch nearby venues if the mapped moved just to show selected venue
    if (this.selectedVenueId) return;

    this.venuesFetcher.fetchVenuesInBounds(mapBounds);
  },

  loadEventsPage(page) {
    this.eventsIndexController.set('page', page);
    this._callEventsFetcher(this.selectedVenueId ? [ this.selectedVenueId ] : this.nearbyVenues.map(v => v.id));
  },

  loadMoreEvents() {
    if (this.eventsFetcher.isAppendingEvents
        || this.eventsFetcher.currentPage >= this.eventsFetcher.totalPageCount)
      return;

    this.eventsIndexController.set('page', this.eventsFetcher.currentPage+1);
    this._callEventsFetcher(
      this.selectedVenueId ? [ this.selectedVenueId ] : this.nearbyVenues.map(v => v.id),
      true);
  },

  async _callEventsFetcher(venues, appendEvents) {
    // don't call events fetcher on tablet or mobile because the sidebar is not visible anyway
    if (!this.fetchForWebEventsList && (this.media.isTablet || this.media.isMobile))
      return;

    const fromDate = this.eventsIndexController.fromDate || this.model.fromDate;
    const toDate = this.eventsIndexController.toDate || this.model.toDate;

    let geolocation = this.model.userLocation;
    if (venues.length == 0 && this.model.city) {
      const geocoder = new window.google.maps.Geocoder();
      await Promise.resolve(geocoder.geocode({'address': this.model.city}))
        .then((geocodeResult) => {
          geolocation = {
            latitude: geocodeResult.results[0].geometry.location.lat(),
            longitude: geocodeResult.results[0].geometry.location.lng(),
          };
        });
    }

    this.eventsFetcher.fetchEvents({
      forVenueIds: venues,
      page: this.selectedPage,
      fromDate,
      toDate,
      geolocation,
      appendEvents,
      fetchForWebEventsList: this.fetchForWebEventsList,
      fetchEventsWithPhotosOnly: this.fetchEventsWithPhotosOnly});
  }
});

import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { run } from '@ember/runloop';
import { computed, observer } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import mapStyles from 'gigshq/constants/map-styles';
import getMapPin from 'gigshq/constants/map-pins';
import config from 'gigshq/config/environment';

const MAP_OPTIONS = {
  styles: mapStyles,
  backgroundColor: '#1f1f1f',
  streetViewControl: false,
  gestureHandling: 'greedy',
  zoomControl: true,
};

const INITIAL_RADIUS_OPTIONS = {
  radius: 2000 // Radius in meters: 10 km
};

const MARKER_OPTIONS = {
  clickable: true,
  optimized: false,
  zIndex: 9999
};

/* eslint-disable no-magic-numbers */
const DEFAULT_MARKER = (venueCategory) => ({
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  labelOrigin: new window.google.maps.Point(40, 30),
  url: getMapPin(venueCategory),
});

const DEFAULT_MARKER_HAS_OFFERS = (venueCategory) => ({
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  labelOrigin: new window.google.maps.Point(40, 30),
  url: getMapPin(venueCategory, 'offers'),
});

const DEFAULT_MARKER_LABEL = (venueName, noEvents, isFavourite) => ({
  text: (venueName.length > 16) ? `${venueName.substring(0, 16)}...` : venueName,
  className: 'gigs-web-map__marker-label'
    + (noEvents ? ' gigs-web-map__marker-label_no_events' : '')
    + (isFavourite ? ' gigs-web-map__marker-label_favourite' : ''),
  color: 'white',
  fontSize: '14px'
});

const USER_MARKER = () => ({
  anchor: new window.google.maps.Point(0, 30),
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  url: '/assets/images/img-map-pin-user.png'
});

const FAVOURITE_MARKER = () => ({
  anchor: new window.google.maps.Point(0, 30),
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  url: '/assets/images/img-map-pin-favourite.png'
});

const FAVOURITE_MARKER_HAS_OFFERS = () => ({
  anchor: new window.google.maps.Point(0, 30),
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  url: '/assets/images/img-map-pin-favourite-offers.png'
});

const NO_EVENT_MARKER = (venueCategory) => ({
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  labelOrigin: new window.google.maps.Point(20, 37),
  url: getMapPin(venueCategory, 'eventless-venue'),
});

const NO_EVENT_MARKER_OFFERS = (venueCategory) => ({
  size: new window.google.maps.Size(45, 45),
  scaledSize: new window.google.maps.Size(45, 45),
  labelOrigin: new window.google.maps.Point(20, 37),
  url: getMapPin(venueCategory, 'eventless-venue-offers'),
});

const CUSTOM_CLUSTERER_STYLES = [{
  width: 30,
  height: 30,
  className: 'custom-clustericon-1'
},
{
  width: 20,
  height: 20,
  className: 'custom-clustericon-2'
},
{
  width: 50,
  height: 50,
  className: 'custom-clustericon-3'
},
{
  width: 60,
  height: 60,
  className: 'custom-clustericon-4'
},
{
  width: 70,
  height: 70,
  className: 'custom-clustericon-5'
}];

const SELECTED_VENUE_ZOOM_LEVEL = 17;
const SELECTED_VENUE_PAN_OFFSET = 0.002;

export default Component.extend(Localizable(translations), {
  propTypes: {
    venues: T.array.isRequired,
    selectedVenue: T.nullable(T.object),
    userLocation: T.nullable(
      T.shape({
        latitude: T.number.isRequired,
        longitude: T.number.isRequired
      })
    ),
    toDate: T.string.isRequired,
    fromDate: T.string.isRequired,
    city: T.string,
    mapWithinZoomLimits: T.bool.isRequired,
    onMapCenterChanged: T.func.isRequired
  },

  media: service(),
  favouriteVenuesManager: service('managers/favouriteVenuesManager'),
  sessionStorage: service('session-storage'),

  venueAddress: computed('infoWindowVenue', function () {
    const { formattedAddress } = this.infoWindowVenue;
    return formattedAddress
      .split(', ')
      .slice(0, -1)
      .join(', ');
  }),

  infoWindowVenue: null,

  infoWindowVenueIsFavourite: computed(function () {
    return (
      this.infoWindowVenue &&
      this.favouriteVenuesManager.isFavouriteVenue(this.infoWindowVenue)
    );
  }).volatile(),

  selectedVenueDidChange: observer('selectedVenue.id',
    function () {
      run.next(() => {
        this.refreshMarkers();
        this.centerMapOnSelectedVenue();
      });
    }
  ),

  venuesNearbyDidChange: observer('venues',
    function () {
      run.next(() => this.refreshMarkers());
    }
  ),

  mapCenter: computed('selectedVenue', 'userLocation', 'city', function () {
    if (this.city) {
      const geocoder = new window.google.maps.Geocoder();
      return Promise.resolve(geocoder.geocode({'address': this.city}))
        .then((geocodeResult) => {
          return {
            latitude: geocodeResult.results[0].geometry.location.lat(),
            longitude: geocodeResult.results[0].geometry.location.lng(),
          };
        });
    }

    if (this.selectedVenue) {
      return Promise.resolve(this.selectedVenue)
        .then((result) => {
          return {
            latitude: result.latitude,
            longitude: result.longitude
          };
       });
    }

    const persistedCenter = this.fetchMapCenter();
    if (persistedCenter) {
      return persistedCenter;
    }

    if (this.userLocation) {
      return this.userLocation;
    }

    return config.MapConfiguration.FALLBACK_MAP_CENTER;
  }).volatile(),

  mapZoom: computed(function () {
    const persistedMapZoom = this.fetchMapZoom();
    if (persistedMapZoom)
      return persistedMapZoom;

    return null;
  }).volatile(),

  showEventsOnly: computed(function () {
    return this.sessionStorage.read('showEventsOnly');
  }).volatile(),

  renderMapTask: task(function* () {
    yield this.renderMap(yield this.mapCenter, this.mapZoom);
  }).restartable(),

  didUpdateAttrs() {
  },

  didInsertElement() {
    this.get('renderMapTask').perform();
  },

  willDestroyElement() {
    this.destroyMarkers();
    this.unbindInfoWindowEvents();
    this.unbindMapEvents();
  },

  searchControl() {
    const centerControlDiv = document.createElement('div');
    centerControlDiv.style.zIndex = 100;

    // Set CSS for the control border.
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '10px';
    controlUI.style.textAlign = 'center';
    controlUI.style.width = '50px';
    controlUI.title = this.localTranslation('search_button.title');
    centerControlDiv.appendChild(controlUI);
    // Set CSS for the control interior.
    const controlText = document.createElement('div');
    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '40px';
    controlText.style.paddingLeft = '15px';
    controlText.style.paddingRight = '20px';
    controlText.innerHTML = '🔍';
    controlUI.appendChild(controlText);
    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      this.set('mapSearchModalIsOpened', true);
    });
    return centerControlDiv;
  },

  popupSearchBar() {
    const map = this.googleMap;
    const input = document.getElementById('popup-search-bar');
    const searchBox = new window.google.maps.places.SearchBox(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          // Returned place contains no geometry
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
      this.close();
    });
  },

  searchBar(map) {
    // Create the search box and link it to the UI element.
    const input = document.createElement('input');
    input.className = 'controls';
    input.type = 'text';
    input.placeholder = this.localTranslation('search_bar.placeholder');

    input.style.backgroundColor = '#fff';
    input.style.borderRadius = '3px';
    input.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    input.style.cursor = 'pointer';
    input.style.marginTop = '10px';
    input.style.height = '40px';
    input.style.fontSize = '18px';
    input.style.width = '35%';
    input.style.paddingLeft = '5px';

    const searchBox = new window.google.maps.places.SearchBox(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();

      if (places[0].geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(places[0].geometry.viewport);
      } else {
        bounds.extend(places[0].geometry.location);
      }

      if (this.placeMarker) {
        this.placeMarker.setMap(null);
      }

      this.set('placeMarker', new window.google.maps.Marker({
        position: places[0].geometry.location,
        title: places[0].name,
        icon: USER_MARKER(),
        map
      }));

      map.fitBounds(bounds);
    });
    return input;
  },

  showEventsOnlyControl() {
    // Set CSS for the control border.
    const container = document.createElement('div');
    container.className = 'sliderContainer'

    const showEventsOnlyLbl = document.createElement('label');
    showEventsOnlyLbl.className = 'sliderLbl';
    showEventsOnlyLbl.innerHTML = this.localTranslation(this.media.isMobile ? 'show_events_slider.mobile' : 'show_events_slider.desktop');

    const switchLbl = document.createElement('label');
    switchLbl.className = 'switch';

    const input = document.createElement('input');
    input.id = 'showEventsOnlySlider';
    input.className = 'controls sliderCheckBox';
    input.type = 'checkbox';
    input.checked = this.showEventsOnly;

    input.addEventListener('click', () => {
      this.sessionStorage.write('showEventsOnly', input.checked);
      this.renderMarkers();
    });

    const sliderSpan = document.createElement('span');
    sliderSpan.className = 'slider round';

    switchLbl.appendChild(input);
    switchLbl.appendChild(sliderSpan);
    container.appendChild(showEventsOnlyLbl);
    container.appendChild(switchLbl);

    return container;
  },

  centerOnCurrentLocationControl(map) {
    const centerOnCurrentLocationBtn = document.createElement('button');

    centerOnCurrentLocationBtn.id = 'centerOnCurrentLocationBtn';
    centerOnCurrentLocationBtn.className = 'controls centerOnLocation';
    centerOnCurrentLocationBtn.title = this.localTranslation('center_on_my_position.text');

    let location = config.MapConfiguration.FALLBACK_MAP_CENTER;

    if (this.userLocation) {
      location = {
                  lat: this.userLocation.latitude,
                  lng: this.userLocation.longitude
                 };
    }

    centerOnCurrentLocationBtn.addEventListener('click', () => {
      map.panTo(location);
    });

    return centerOnCurrentLocationBtn;
  },

  setGlobalMap(map) {
    window.globalMap = map;
  },

  renderMap(coordinates, zoom) {
    const center = new window.google.maps.LatLng(
      coordinates.latitude,
      coordinates.longitude
    );

    const element = this.$('.gigs-web-map__map').get(0);

    const map = new window.google.maps.Map(element, this.getMapOptions(zoom, center));

    this.setGlobalMap(map);

    this.setMapControls();

    const zoomListener = map.addListener('zoom_changed', () =>
      this.persistMapZoom()
    );
    const centerListener = map.addListener('center_changed', () =>
        this.persistMapCenter()
    );

    const idleListener = map.addListener('idle', () => {
      // only get nearby venues when you're zoomed in close enough
      const mapWithinZoomLimits = map.getZoom() >= config.MapConfiguration.GOOGLE_MAPS_MIN_ZOOM;
      this.set('mapWithinZoomLimits', mapWithinZoomLimits);

      const mapBounds = mapWithinZoomLimits ? this.get('map').map.getBounds() : null;
      this.onMapCenterChanged(mapBounds);
    });

    const userPosition = this.userLocation ?
                       new google.maps.LatLng(this.userLocation.latitude,
                                              this.userLocation.longitude) :
                       new google.maps.LatLng(config.MapConfiguration.FALLBACK_MAP_CENTER.latitude,
                                              config.MapConfiguration.FALLBACK_MAP_CENTER.longitude)

    const user_marker = new window.google.maps.Marker({
      position: userPosition,
      title: this.localTranslation('you_are_here.text').toString(),
      icon: USER_MARKER(),
      map
    });

    this.set('map', { map, zoomListener, centerListener, idleListener });

    if (!zoom) {
        const initialRadius = new window.google.maps.Circle({
          ...INITIAL_RADIUS_OPTIONS,
          center
        });

        map.fitBounds(initialRadius.getBounds());
    }

    if (this.venues.length > 0)
      this.refreshMarkers();

    return;
  },

  setMapControls() {
    const map = window.globalMap;

    if (map) {
      const input = this.media.isMobile ? this.searchControl(map) : this.searchBar(map);

      map.controls[google.maps.ControlPosition.TOP_LEFT].clear();
      map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();

      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      map.controls[this.media.isMobile ?
                  google.maps.ControlPosition.TOP_RIGHT :
                  google.maps.ControlPosition.TOP_LEFT]
                  .push(this.showEventsOnlyControl(map));

      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM ].push(this.centerOnCurrentLocationControl(map));
    }
  },

  getMapOptions(zoom, center) {
    const options = {
      ...MAP_OPTIONS,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      center
    };

    if (this.media.isMobile) {
      options.mapTypeControl = true;
      options.mapTypeControlOptions = {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['roadmap', 'satellite']
      };
      options.fullscreenControl = true;
      options.fullscreenControlOptions = {
        position: google.maps.ControlPosition.BOTTOM_LEFT
      };
    }

    if (zoom) options.zoom = zoom;

    return options;
  },

  async renderMarkers() {
    let nearVenues = this.venues;

    if (this.selectedVenue) {
      Promise.resolve(this.selectedVenue).then((result) => {
        if (!nearVenues.find(venue => venue.id === result.id))
          nearVenues.push(result);
      });
    }

    if (this.markers) {
      nearVenues = nearVenues.filter((venue) => {
        return !this.markers.find(marker => marker.venue.id === venue.id);
      });
    }

    const newMarkers = nearVenues.map(venue => {
      const position = new window.google.maps.LatLng(venue.latitude, venue.longitude);
      const upcomingEventsCount = venue.upcomingEvents.length;
      const { icon, label, isLargeIcon } = this.getIconForVenue(venue, upcomingEventsCount);

      const marker = new window.google.maps.Marker({
        ...MARKER_OPTIONS,
        icon,
        label,
        position
      });

      const listener = marker.addListener('click', () => this.createInfoWindow(venue, marker, isLargeIcon));

      if (this.selectedVenue && venue.id === this.selectedVenue.id)
        this.createInfoWindow(venue, marker, isLargeIcon);

      return { marker, listener, venue, upcomingEventsCount };
    });

    if (this.markers) {
      newMarkers.forEach(m => {
        if (this.markers.indexOf(m) < 0)
          this.markers.push(m);
      });
    } else {
      this.set('markers', newMarkers);
      let markerClusterer = new MarkerClusterer(this.map.map,
        this.markers.map(m => m.marker),
        {
          averageCenter: true,
          minimumClusterSize: this.showEventsOnly || this.markers.length < 400 ? 5 : 2,
          clusterClass: 'custom-clustericon',
          styles: CUSTOM_CLUSTERER_STYLES,
          gridSize: 80,
          ignoreHidden: true
        });

      markerClusterer.setCalculator(function (markers, maxIndex) {
        return {
          text: markers.length,
          index: 1
        }
      });
      this.set('markerClusterer', markerClusterer);
    }

    this.set('markers', this.markers.filter(m => { return this.setMarkerVisible(m) }));

    this.markerClusterer.setMinimumClusterSize(this.showEventsOnly || this.markers.length < 400 ? 5 : 2);
    this.markerClusterer.repaint();
  },

  setMarkerVisible(marker) {
    const visible = marker.upcomingEventsCount > 0 || !this.showEventsOnly;

    if (visible) {
      if (this.markerClusterer.getMarkers().indexOf(marker.marker) < 0) {
        this.markerClusterer.addMarker(marker.marker, true);
      }
      return true;
    } else {
      this.markerClusterer.removeMarker(marker.marker, true);
      return false;
    }
  },

  destroyMarkers() {
    const mapBounds = this.map.map.getBounds();
    if (mapBounds && this.markers) {
      const markersToKeep = [];

      this.markers.forEach(marker => {
        if (marker.listener) {
          marker.listener.remove();
        }
        marker.marker.setMap(null);
        this.markerClusterer.removeMarker(marker.marker, true);
      }, this);

      this.set('markers', markersToKeep.length > 0 ? markersToKeep : null);
      this.markerClusterer.repaint();
    }
  },

  isMarkerDirty(marker, mapBounds) {
    return (this.infoWindowVenue && this.selectedVenue &&
      this.infoWindowVenue.id !== this.selectedVenue.id &&
      (marker.venue.id === this.infoWindowVenue.id ||
        marker.venue.id === this.selectedVenue.id)) ||
      !mapBounds.contains(marker.marker.position);
  },

  refreshMarkers() {
    this.destroyMarkers();
    this.renderMarkers();
  },

  getIconForVenue(venue, upcomingEventsCount) {
    if (this.selectedVenue) {
      return this.getIconForSelectedVenue(venue);
    }

    if (this.favouriteVenuesManager.isFavouriteVenue(venue)) {
      return {
        icon: venue.hasVenueOffers ? FAVOURITE_MARKER_HAS_OFFERS() : FAVOURITE_MARKER(),
        label: DEFAULT_MARKER_LABEL(venue.name, upcomingEventsCount === 0, true),
        isLargeIcon: true
      };
    }

    return upcomingEventsCount > 0
      ? {
        icon: venue.hasVenueOffers ? DEFAULT_MARKER_HAS_OFFERS(venue.venueCategory) : DEFAULT_MARKER(venue.venueCategory),
        label: DEFAULT_MARKER_LABEL(venue.name, false), isLargeIcon: true
      }
      : {
        icon: venue.hasVenueOffers ? NO_EVENT_MARKER_OFFERS(venue.venueCategory) : NO_EVENT_MARKER(venue.venueCategory),
        label: DEFAULT_MARKER_LABEL(venue.name, true), isLargeIcon: false
      };
  },

  getIconForSelectedVenue(venue) {
    if (this.selectedVenue.id === venue.id) {
      return this.favouriteVenuesManager.isFavouriteVenue(venue)
        ? {
          icon: venue.hasVenueOffers ? FAVOURITE_MARKER_HAS_OFFERS() : FAVOURITE_MARKER(),
          isLargeIcon: true
        }
        : {
          icon: venue.hasVenueOffers ? DEFAULT_MARKER_HAS_OFFERS(venue.venueCategory) : DEFAULT_MARKER(venue.venueCategory),
          isLargeIcon: true
        };
    } else {
      return {
        icon: venue.hasVenueOffers ? NO_EVENT_MARKER_OFFERS(venue.venueCategory) : NO_EVENT_MARKER(venue.venueCategory),
        isLargeIcon: false
      };
    }
  },

  createInfoWindow(venue, marker, isLargeIcon) {
    if (venue.name == null) return;

    if (this.infoWindow && this.infoWindowVenue.id !== venue) this.infoWindow.close();

    const options = isLargeIcon
      ? // eslint-disable-next-line no-magic-numbers
      { pixelOffset: new window.google.maps.Size(-12, 0) }
      : {};

    const infoWindow = new window.google.maps.InfoWindow(options);

    const infoWindowVenue = venue;

    this.setProperties({ infoWindow, infoWindowVenue, infoWindowMarker: marker });
    this.notifyPropertyChange('infoWindowVenueIsFavourite');

    run.next(() => {
      this.setInfoWindowContent();

      infoWindow.open(this.map.map, marker);
    });
  },

  setInfoWindowContent() {
    this.unbindInfoWindowEvents();

    this.infoWindow.setContent(
      this.$('.gigs-web-map__info-window-content').html()
    );

    this.bindInfoWindowEvents();
  },

  bindInfoWindowEvents() {
    this.$('.gigs-web-map__map').on(
      'click.favourite',
      '.gigs-web-map__info-window-favourite-button',
      () => {
        this.favouriteVenuesManager.toggle(this.infoWindowVenue);
        this.notifyPropertyChange('infoWindowVenueIsFavourite');

        const { icon, isLargeIcon } = this.getIconForVenue(this.infoWindowVenue);

        const options = isLargeIcon
          ? // eslint-disable-next-line no-magic-numbers
          { pixelOffset: new window.google.maps.Size(-12, 0) }
          : { pixelOffset: new window.google.maps.Size(0, 0) };

        this.infoWindowMarker.setIcon(icon);
        this.infoWindow.setOptions(options);

        run.next(() => {
          this.setInfoWindowContent();
        });
      }
    );
  },

  unbindInfoWindowEvents() {
    this.$('.gigs-web-map__map').off('click.favourite');
  },

  unbindMapEvents() {
    const { zoomListener, centerListener } = this.map;

    zoomListener.remove();
    centerListener.remove();
  },

  centerMapOnSelectedVenue() {
    if (!this.selectedVenue) return;

    this.map.map.setZoom(SELECTED_VENUE_ZOOM_LEVEL);

    this.map.map.panTo({
      lat: this.selectedVenue.latitude + SELECTED_VENUE_PAN_OFFSET,
      lng: this.selectedVenue.longitude
    });

    /*
    this.createInfoWindow(
      this.selectedVenue,
      this.markers.find(marker => marker.venue.id === this.selectedVenue.id).marker,
      true
    );
    */
  },

  persistMapZoom() {
    const { map } = this.map;

    this.sessionStorage.write('mapZoom', map.getZoom());
  },

  fetchMapZoom() {
    return this.sessionStorage.read('mapZoom');
  },

  persistMapCenter() {
    const { map } = this.map;

    const center = map.getCenter();

    this.sessionStorage.write('mapCenter', {
      latitude: center.lat(),
      longitude: center.lng()
    });
  },

  fetchMapCenter() {
    return this.sessionStorage.read('mapCenter');
  },

  mapSearchModalIsOpened: false,

  actions: {
    displayMapSearchModal() {
      this.set('mapSearchModalIsOpened', true);
    },

    hideMapSearchModal() {
      this.set('mapSearchModalIsOpened', false);
    }
  }
});

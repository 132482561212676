import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import QRCode from 'qrcode';

export default Component.extend(Localizable(translations), {
  propTypes: {
    user: T.object.isRequired,
    saveName: T.func.isRequired,
    saveEmail: T.func.isRequired,
  },

  tagName: 'tr',

  classNameBindings: ['isEditingName', 'isEditingEmail'],

  firstName: reads('user.firstName'),
  lastName: reads('user.lastName'),
  email: reads('user.email'),
  promoCode: reads('user.promoCode'),
  referralLink: reads('user.referralLink'),
  userId: reads('user.id'),

  downloadQrCode: null,

  didReceiveAttrs() {
    const component = this;
    if (this.get('referralLink')) {
      QRCode.toDataURL(this.get('referralLink'), {}, function(_err, content) {
        if (content)
          component.downloadQrCode = content.replace("image/png", "image/octet-stream"); // it will save locally
      });
    }
  },

  actions: {
    editName() {
      this.set('isEditingName', true);
    },

    editEmail() {
      this.set('isEditingEmail', true);
    },

    handleSaveName({firstName, lastName}) {
      const userId = this.get('user.id');

      this.saveName({userId, firstName, lastName});

      this.set('isEditingName', false);
    },

    handleSaveEmail(email) {
      const userId = this.get('user.id');

      this.saveEmail({userId, email});

      this.set('isEditingEmail', false);
    }
  }
});

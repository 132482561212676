import Service, { inject as service } from '@ember/service';
import config from 'gigshq/config/environment';
import RSVP from 'rsvp';

export default Service.extend({
  fastboot: service(),

  fetch() {
    if (this.fastboot.isFastBoot) return {latitude: 0, longitude: 0};

    return new RSVP.Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) resolve(config.MapConfiguration.FALLBACK_MAP_CENTER);

      navigator.geolocation.getCurrentPosition(
        ({coords}) => resolve(coords),
        () => resolve(config.MapConfiguration.FALLBACK_MAP_CENTER),
        {
          timeout: 10000 // 10 seconds
        }
      );
    });
  },

  calcDistance(pointA, pointB) {
    if (!pointA || !pointB)
      return 0;

    var R = 6371.0710; // Radius of the Earth in km
    var rlat1 = pointA.latitude * (Math.PI/180); // Convert degrees to radians
    var rlat2 = pointB.latitude * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (pointB.longitude - pointA.longitude) * (Math.PI/180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    return d.toFixed(2);
  }
});

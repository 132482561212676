import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { notEmpty, filterBy } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import locales from 'gigshq/constants/locales';
import genders from 'gigshq/constants/genders';
import DEFAULT_MEMBERSHIP_PLANS from 'gigshq/constants/membership-plans';
import config from 'gigshq/config/environment';
import { task } from 'ember-concurrency';
import QRCode from 'qrcode';

import mutation from 'gigshq/graphql/mutations/link-tapfiliate';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    data: T.nullable(T.object).isRequired,
    errors: T.nullable(T.object).isRequired,
    firstName: T.string.isRequired,
    lastName: T.string.isRequired,
    gender: T.string.isRequired,
    birthdate: T.string.isRequired,
    contactNumber: T.string.isRequired,
    email: T.string.isRequired,
    address: T.string.isRequired,
    city: T.string.isRequired,
    province: T.string.isRequired,
    postalCode: T.string.isRequired,
    country: T.string.isRequired,
    promoCode: T.string,
    referralLink: T.string,
    usedPromoCode: T.string,
    stripeProductId: T.string,
    setStripeElement: T.func,
    stripeError: T.object,
    locale: T.string.isRequired,
    setProperty: T.func.isRequired,
    setMemberField: T.func.isRequired,
    resetForm: T.func.isRequired
  },

  apollo: service(),

  tagName: 'form',
  availableLocales: locales,
  genderOptions: genders,
  isLinkPending: false,
  linkErrorMessage: null,

  membershipPlans: computed(function() {
    if (config.environment === 'production')
      return [...DEFAULT_MEMBERSHIP_PLANS.prdPlans];
    else
      return [...DEFAULT_MEMBERSHIP_PLANS.devPlans];
  }),

  visibleMembershipPlans: filterBy('membershipPlans', 'visible', true),

  hideStripeCardFields: computed('stripeProductId', 'user.member.stripeProductId', function() {
    const subscription = this.get('stripeProductId') || this.user.member.stripeProductId;
    const noSubscription = subscription == null;
    const isFreeSubscription = this.membershipPlans
      .find((plan) => plan.stripeProductId === subscription)?.key === 'free';
    return noSubscription || isFreeSubscription;
  }),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  hasAddress: notEmpty('user.member.address'),
  hasMembershipAccount: notEmpty('user.member.stripeProductId'),

  currentMembershipStatus: computed('user.member.membershipStatus', function() {
    if (!this.hasMembershipAccount)
      return 'incomplete';

    return this.user.member.membershipStatus;
  }),

  currentMembershipPlanKey: computed('user.member.stripeProductId', function() {
    if (!this.hasMembershipAccount)
      return 'none';

    return this.membershipPlans.find((plan) => plan.stripeProductId === this.user.member.stripeProductId)?.key;
  }),

  stripeCardBrand: computed('user.member.stripeCardBrand', function() {
    return `cc-${this.user.member.stripeCardBrand?.toLowerCase()}`;
  }),

  stripeCardExpiry: computed(
      'user.member.stripeCardExpiryMonth',
      'user.member.stripeCardExpiryYear',
      function() {
    if (!this.hasMembershipAccount)
      return null;

    const expiryMonth = (this.user.member.stripeCardExpiryMonth + '').padStart(2, '0');
    const expiryYear = this.user.member.stripeCardExpiryYear % 100;
    return `${expiryMonth}/${expiryYear}`;
  }),

  downloadQrCode: null,

  didReceiveAttrs() {
    const component = this;
    if (this.get('referralLink')) {
      QRCode.toDataURL(this.get('referralLink'), {}, function(_err, content) {
        if (content)
          component.downloadQrCode = content.replace("image/png", "image/octet-stream"); // it will save locally
      });
    }
  },

  linkTapfiliate: task(function*() {
    const variables = {
      userId: this.user.id
    };

    if (!this.get('isLinkPending')) {
      this.set('isLinkPending', true);
      this.set('linkErrorMessage', null);

      try {
        const result = yield this.apollo.mutationOperation()({mutation, variables}, 'linkTapfiliate');
        this.setProperty('promoCode', result.user.promoCode);
        this.setProperty('referralLink', result.user.referralLink);
      } catch (errors) {
        const message = this.localTranslation(`profile.affiliate.errors.${errors[0].messages[0]}`);
        this.set('linkErrorMessage', message);
      }

      this.set('isLinkPending', false);
    }

  }).drop(),

  actions: {
    selectLocale(locale) {
      this.setProperty('locale', locale.key);
    },

    selectGender(gender) {
      this.setMemberField('gender', gender.key);
    },

    selectMembershipPlan(plan) {
      this.setMemberField('stripeProductId', plan.stripeProductId);
    },

    updatePaymentMethod() {
      if (!this.hasAddress) {
        window.alert(this.localTranslation('add_member_info_first'));
        return;
      }

      this.set('showMembershipPaymentForm', true);
    },

    linkTapfiliate() {
      this.linkTapfiliate.perform();
    },
 }
});
